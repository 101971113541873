import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Hero from 'components/hero';
import Container from '@material-ui/core/Container';
import {
  TitleText,
  FullImage,
  Statistics,
  ImageGrid,
  LatestNews,
  TabMenu,
  QuoteImageBlock,
  TeamMembers,
  ContentImageBlock,
  Videos,
  ContactSection,
  Carousel,
} from 'components/slices';
import { withPreview } from 'gatsby-source-prismic';
import { Helmet } from 'react-helmet';

function Page(props) {
  const { data } = props;
  const doc = data.prismicPage;
  if (!doc) return null;
  return (
    <Layout>
      {doc.data.metadata && doc.data.metadata[0] && (
        <Helmet>
          {doc.data.metadata[0].title && doc.data.metadata[0].title[0] && (
            <title>{doc.data.metadata[0].title[0].text}</title>
          )}
          {doc.data.metadata[0].description &&
            doc.data.metadata[0].description[0] && (
              <Helmet>
                <meta
                  name="description"
                  content={doc.data.metadata[0].description[0].text}
                />
                <meta
                  property="og:description"
                  content={doc.data.metadata[0].description[0].text}
                />
                <meta
                  name="twitter:description"
                  content={doc.data.metadata[0].description[0].text}
                />
              </Helmet>
            )}
          {doc.data.metadata[0].keywords &&
            doc.data.metadata[0].keywords[0] && (
              <meta
                name="keywords"
                content={doc.data.metadata[0].keywords[0].text}
              />
            )}
        </Helmet>
      )}
      <Hero data={doc.data} />
      {doc.data.body && (
        <Container maxWidth="xl">
          {doc.data.body.map((item, i) => {
            switch (item.slice_type) {
              case 'title___text':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <TitleText data={item.primary} />
                    </div>
                  </div>
                );
              case 'full_image_block':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <FullImage data={item.primary} />
                    </div>
                  </div>
                );
              case 'statistics':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <Statistics data={item.items} />
                    </div>
                  </div>
                );
              case 'image_grid':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <ImageGrid data={item.items} />
                    </div>
                  </div>
                );
              case 'latest_news':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <LatestNews data={item.primary} />
                    </div>
                  </div>
                );
              case 'tab_menu':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <TabMenu data={item.items} primary={item.primary} />
                    </div>
                  </div>
                );
              case 'quote_image_block':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <QuoteImageBlock data={item.primary} />
                    </div>
                  </div>
                );
              case 'team_members':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <TeamMembers data={item.items} primary={item.primary} />
                    </div>
                  </div>
                );
              case 'content_image_block':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <ContentImageBlock data={item.primary} />
                    </div>
                  </div>
                );
              case 'videos':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <Videos data={item.items} />
                    </div>
                  </div>
                );
              case 'contact_form_50_50_section':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <ContactSection data={item.primary} />
                    </div>
                  </div>
                );
              case 'carousel':
                return (
                  <div key={i}>
                    <div className="spacer">
                      <Carousel fields={item.items} />
                    </div>
                  </div>
                );
              default:
                return null;
            }
          })}
        </Container>
      )}
    </Layout>
  );
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withPreview(Page);

export const query = graphql`
  query StandardPageQuery($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      uid
      data {
        metadata {
          description {
            text
          }
          keywords {
            text
          }
          title {
            text
          }
        }
        banner_image {
          url
          alt
        }
        banner_title {
          html
          raw
          text
        }
        page_title {
          html
          raw
          text
        }
        body {
          ... on PrismicPageBodyCarousel {
            slice_type
            items {
              title {
                html
                raw
                text
              }
              content {
                html
                raw
                text
              }
              image {
                url
              }
              link {
                url
              }
            }
          }
          ... on PrismicPageBodyTitleText {
            id
            slice_type
            primary {
              title {
                html
                raw
                text
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageBodyFullImageBlock {
            id
            slice_type
            primary {
              title {
                text
                raw
                html
              }
              link {
                uid
              }
              image {
                url
                alt
              }
              caption {
                text
                raw
                html
              }
            }
          }
          ... on PrismicPageBodyStatistics {
            slice_type
            items {
              title {
                html
                raw
                text
              }
              caption {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageBodyImageGrid {
            slice_type
            items {
              title {
                text
                raw
                html
              }
              link {
                url
                uid
                type
              }
              image {
                url
                alt
              }
              caption {
                text
                raw
                html
              }
            }
          }
          ... on PrismicPageBodyLatestNews {
            slice_type
            primary {
              number_of_articles
            }
          }
          ... on PrismicPageBodyTabMenu {
            id
            slice_type
            primary {
              anchor_element
            }
            items {
              content {
                text
                raw
                html
              }
              menu_item_title {
                text
                raw
                html
              }
            }
          }
          ... on PrismicPageBodyQuoteImageBlock {
            slice_type
            primary {
              title {
                html
                raw
                text
              }
              quote {
                html
                raw
                text
              }
              job_title {
                html
                raw
                text
              }
              image {
                url
                alt
              }
              brand {
                alt
                url
              }
              author {
                text
                raw
                html
              }
            }
          }
          ... on PrismicPageBodyTeamMembers {
            slice_type
            primary {
              anchor_element
            }
            items {
              image {
                url
                alt
              }
              job_title {
                html
                raw
                text
              }
              name {
                html
                raw
                text
              }
              profile {
                html
                raw
                text
              }
              credentials {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageBodyContentImageBlock {
            slice_type
            primary {
              title {
                html
                raw
                text
              }
              image {
                alt
                url
              }
              content_box_position
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageBodyVideos {
            slice_type
            items {
              video {
                html
              }
              title {
                html
                raw
                text
              }
              caption {
                html
                text
                raw
              }
              image {
                alt
                url
              }
            }
          }
          ... on PrismicPageBodyContactForm5050Section {
            slice_type
            primary {
              content {
                html
                raw
                text
              }
            }
          }
        }
      }
    }
  }
`;
